// xmpp聊天室数据
import { defineStore } from 'pinia'
import chatRoomFetch from './chatRoomFetch'

const getDefaultState = () => {
  return {
    activeChat: null,
    messages: [],
    contacts: [],
    groups: [],
    joinedRooms: [],
    knownRooms: [],
    roomsOccupants: [],
    httpFileUploadMaxSize: null,
    isOnline: false,
    presence: 'chat',
    hasNotificationsEnabled: false,
    isSendingTypingChatStates: true,
    isSendingInactiveChatStates: false,
    currentRoomAffiliations: {}, // 当前房间人员角色信息
    openImUserInfo: {},
    autoMessage: {},
    current_lottery: {}
  }
}

export const useChatRoomStore = defineStore('chatRoom', {
  state: () => {
    return {
      hasNetwork: null,
      ...getDefaultState(),
    }
  },

  getters: {

    publicRooms: (state) => {
      return state.knownRooms.filter((room) => room.isPublic)
    },

    bookmarkedRooms: (state) => {
      return state.knownRooms.filter((room) => room.isBookmarked)
    },

    getRoom: (state) => (jid) => {
      return state.knownRooms.find((room) => room.jid === jid) || {}
    },

    isBookmarked: (state) => (jid) => {
      return state.knownRooms.some((room) => room.jid === jid && room.isBookmarked)
    },

    isJoined: (state) => (jid) => {
      return state.joinedRooms.some((joinedRoomJid) => joinedRoomJid === jid)
    },

    getRoomOccupants: (state) => (jid) => {
      const roomOccupants = state.roomsOccupants.find((roomOccupants) => roomOccupants.roomJid === jid)
      if (roomOccupants) {
        // 设置角色
        return roomOccupants.occupants.map(occ => {
          return {
            ...occ,
            role: state.currentRoomAffiliations[occ.resource] || ''
          }
        })
      }
      return []
    },

    getRoomSubject: (state) => (jid) => {
      const room = state.knownRooms.find((room) => room.jid === jid)
      return (room && room.subject) ? room.subject : null
    },

    getChatState: (state) => (isRoom, jid) => {
      if (isRoom) {
        const roomOccupants = state.roomsOccupants.find((roomOccupants) => roomOccupants.roomJid === jid)
        if (roomOccupants) {
          if (roomOccupants.occupants.some(occupant => occupant.chatState === 'composing')) {
            return 'composing'
          }
          if (roomOccupants.occupants.some(occupant => occupant.chatState === 'paused')) {
            return 'paused'
          }
        }
        return 'inactive'
      }
      const contact = state.contacts.find((contact) => contact.jid === jid)
      return contact ? contact.chatState : 'inactive'
    },
  },

  actions: {
    ...chatRoomFetch,
    // clear state
    clear() {
      const defaultState = getDefaultState()
      Object.keys(defaultState).forEach((key) => {
        this[key] = defaultState[key]
      })
    },
    setCurrentRoomInfo(data) {
      if (data?.affiliations?.length) {
        const affiliationsObj = {}
        data?.affiliations.map(aff => {
          affiliationsObj[aff.name] = aff.aff
        })
        this.currentRoomAffiliations = affiliationsObj
      }
    }
  },
})
