// 信息 公告 banner 相关操作
import { useMsgStore } from '/@/kernel-mn/stores/message';
import { storeToRefs } from 'pinia';
import { get, remove, set } from '/@/kernel-mn/utils/storage';
import {getYMDHMS, isAndroid, isIOS} from '/@/kernel-mn/utils'
import config from '/@/kernel-mn/config';
import { useHot } from '/@/kernel-mn/stores/useHot';

// 获取banner图
export const getBanner = (payload = { num_per_page: 5, chan_con: 8, apply: 8 }) => {
  const msgStore = useMsgStore();
  const { banners } = storeToRefs(msgStore);
  msgStore.reqBanners(payload);
  return banners;
};

// 获取公告
export const getNewList = (payload: any = { num_per_page: 5, chan_con: 8, apply: 8 }) => {
  const msgStore = useMsgStore();
  const { newLists } = storeToRefs(msgStore);
  msgStore.reqNewList(payload);
  return newLists;
};

// 保存公告最大ID
export const saveLastNotice = id => set('lastNotic', id + '');
export const loadLastNotice = () => get('lastNotic') || '';
export const removeLastNotice = () => remove('lastNotic');
// 保存今日不再展示公告弹窗数据
export const saveTodayTime = () => {
  let today = getYMDHMS(new Date(), '', ':', 'YMD');
  set('todayNoticeShow', today)
};
export const removeTodayTime = () => {
  remove('todayNoticeShow');
};
export const isTodayTime = () => {
  let today = getYMDHMS(new Date(), '', ':', 'YMD');
  let local_today = get('todayNoticeShow') || '';
  return +today - +local_today === 0
}

// 获取全部公告
export const getAllNotice = (payload: any = { num_per_page: 5, chan_con: 8, apply: 8 }, nosave = true) => {
  const msgStore = useMsgStore();
  return msgStore.reqNewList(payload, nosave);
};

// 未读消息数量
export const getUnreadNum = (payload: any = {}) => {
  const msgStore = useMsgStore();
  return msgStore.reqWebMessageUnread(payload);
};

export const getChanCon = () => {
  let chan_con = 8
  if ((isIOS()) && config.appVersion > 0) {
    chan_con = 2
  }
  if ((isAndroid()) && config.appVersion > 0) {
    chan_con = 1
  }
  return chan_con
};
// 获取banner和公告
export const getBannerAndNotice = () => {
  const msgStore = useMsgStore();
  const { newLists, banners } = storeToRefs(msgStore);
  return { newLists, banners }
}

/**
 * @description: 发送消息的格式
 * @param {*} type 类型 1 默认消息 2 是跟投消息 3 弹幕消息 4 弹幕图片
 * @param {*} content 消息内容
 * @return {*} 消息结构
 */
export const getSendMsgData = (type, content) => {
  const { hotData, imgDomain } = useHot();
  let { userInfo } = hotData;

  // 消息格式
  const msg = {
    type, // 类型
    nickName: userInfo.value.room_nickname, // 聊天室昵称
    avatar: '', // 头像
    ['content'+type]: content, // 消息内容
  }

  if (userInfo.value?.head) {
    let head = userInfo.value.head;
    if (head.startsWith("/")) {
      head = head.substring(1);
    }
    if (head) {
      msg.avatar = !/^http(s):\/\//.test(head) ? imgDomain.value + head : head;
    }
  }

  return msg
}
