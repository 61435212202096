// import network from '../../network'
// import { useHot } from '/@/kernel-mn/stores/useHot'
// import { getJSON} from '/@/kernel-mn/utils/storage'

export default {
  setData(data) {
    const newData = Object.assign({}, this.data)
    newData[data.key] = data.value
    this.data = newData
  }
}
