import { defineAsyncComponent } from 'vue'
// @ts-ignore
const modules = import.meta.glob('../components/*.vue');

export default {
  install(app) {
    Object.keys(modules).forEach(componentPath => {
      // 获取遍历的当前组件实例对象
      let curComponent = modules[componentPath];
      let name = componentPath.split('/components/').pop().split('.vue')[0];
      app.component(name, defineAsyncComponent(curComponent));
    });
  }
};
