/**
 * @description  按需引入Vant
 */
import {
  allowMultipleToast,
  Lazyload, CheckboxGroup, Checkbox, Empty, NoticeBar, Grid, GridItem,
  Button, Tabbar, TabbarItem, Sticky, NavBar, Icon, Search, DropdownMenu, DropdownItem, Col, Row,
  Image, Tabs, Tab, Field, Cell, CellGroup, Form, List, Popup, Badge, Tag, Dialog, Loading, Radio, RadioGroup,
  Picker, Divider, CountDown, Switch, Slider, Stepper, Popover, Collapse, CollapseItem, ActionSheet,
  Swipe, SwipeItem, Overlay, PullRefresh, TimePicker,PickerGroup,DatePicker, TextEllipsis, FloatingBubble
} from 'vant';

const pluginsVant = [
  Button, Tabbar, TabbarItem, Sticky, NavBar, Icon, Search, DropdownMenu, DropdownItem, Col, Row,
  Image, Tabs, Tab, Field, Cell, CellGroup, Form, List, Popup, Badge, Tag, Dialog, Loading, Radio, RadioGroup,
  Picker, Divider, CountDown, Switch, Slider, Stepper, Popover, Collapse, CollapseItem, ActionSheet,
  CheckboxGroup, Checkbox, Empty, NoticeBar, Grid, GridItem, Swipe, SwipeItem, Overlay, PullRefresh, TimePicker,PickerGroup,DatePicker, TextEllipsis,
  FloatingBubble,
];
allowMultipleToast(); // 多toast模式
export const vantPlugins = {
  install: function (vm) {
    pluginsVant.forEach((item: any) => {
      vm.component(item.name, item);
    });
  }
};

// 不是组件注册的组件
const usepluginsVant = [];
export const useVantPlugins = (app) => {
  // 图片懒加载组件
  app.use(Lazyload, { lazyComponent: true });
  usepluginsVant.forEach((item: any) => { app.use(item) });
};
