import { createApp } from 'vue';
import 'vant/lib/index.css';
// 全局引入按需引入UI库 vant
import { vantPlugins, useVantPlugins } from './plugins/vant';
import { getSession, setJSON, setSession } from "/@/kernel-mn/utils/storage";
import { useHot } from "/@/kernel-mn/stores/useHot";
import { piniaStore } from '/@/kernel-mn/stores';
import global from '/@/plugins/global';
//全局公共组件
import components from '/@/plugins/component';
import App from './App.vue';
// 移动端适配
import 'lib-flexible/flexible.js';

// 语言包
import i18n, { loadLocaleMessages } from '/@/languages';
import dayjs from "dayjs";
import xmppSocket from '/@/xmpp/XmppSocket.js';
import OpenIm from '/@/openIm/OpenIm.js';

// 引入全局样式
import '/@/assets/scss/index.scss';
import router from "/@/router";

// url参数保存 后续备用
let url_serch = window.location.href.split('?')[1] || '';
if (url_serch) {
  let q = url_serch.split('&');
  let o: any = {};
  for (let i = 0; i < q.length; i++) {
    let item = q[i].split('=');
    o[item[0]] = item[1].replace(/\//g, '');
  }
  setJSON('URI_STR', o, true);
  // 根据url设置主题 优先级最高
  o.theme && setSession('themeName', o.theme);
  // url上的邀请码 //
  o.code && setSession('INVITE_CODE', o.code);
};

const init = async () => {
  const app = createApp(App);
  app.use(piniaStore);
  await loadLocaleMessages();
  // @ts-ignore
  app.use(i18n);
  useVantPlugins(app);
  app.use(vantPlugins);
  app.use(global);
  app.use(components);
  app.use(router);
  app.mixin({
    computed: {
      // 主题名 如 'themeQIQUAN'
      THEME() {
        return useHot().tResLoc.value;
      },
      // 租户关系 如 7-5
      CIDTID() {
        let { hotData: { tenantInfo } } = useHot();
        let locid = getSession('CIDTID'); //  获取临时关系 测试环境用的
        return locid || tenantInfo.value.clusters_id + '-' + tenantInfo.value.tid;
      }
    }
  });
  app.config.globalProperties.$xmpp = xmppSocket
  app.config.globalProperties.$openIm = OpenIm
  app.config.globalProperties.$dayjs = dayjs
  app.mount('#app');
}
init();
