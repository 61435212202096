// xmpp聊天室链接
import axios from "axios";
import { useChatRoomStore } from "/@/kernel-mn/stores/chatRoom";
import { storeToRefs } from "pinia";
import { getSession, setSession } from "/@/kernel-mn/utils/storage";

// PC蛋蛋跟其他公共房间的区别
export const PC00Room = (play_name: any) => {
  return ['JND00', 'AZXY20', 'TWBINGO', 'PC005F', 'JNDX00', 'BTB128'].includes(play_name);
}

export const reqApi = async (url = '', payload = {}, method: any = 'get', head = {}) => {
  // @ts-ignore
  const baseURL = window.xmppConfig.posturl || ''
  // 'Content-Type': 'application/json' 997-3
  const headers = { token: 'dbfdPvWC8HDJPAERhwpy', ...head }
  try {
    let res: any
    if (method === 'get') {
      // @ts-ignore
      res = await axios({ method, baseURL, url, timeout: 1000 * 120, headers, params: payload })
    } else {
      // @ts-ignore
      res = await axios({ method, baseURL, url, timeout: 1000 * 120, headers, data: payload, emulateJSON: true })
    }
    return Promise.resolve(res.data || null)
  } catch (err) {
    return Promise.reject(err.data || err.message)
  }
}

// export const reqApi = async (url = '', payload = {}, method: any = 'get', head = {}) => {
//   const baseURL = 'http://43.199.87.0:18001'
//   const headers = { ...head }
//   try {
//     let res: any
//     if (method === 'get') {
//       // @ts-ignore
//       res = await axios({ method, baseURL, url, timeout: 1000 * 120, headers, params: payload })
//     } else {
//       // @ts-ignore
//       res = await axios({ method, baseURL, url, timeout: 1000 * 120, headers, data: payload, emulateJSON: true })
//     }
//     return Promise.resolve(res.data || null)
//   } catch (err) {
//     return Promise.reject(err.data || err.message)
//   }
// }

// 数组中随机取一个 count 个数
export const getRandomByArr = (arr = [], count = 1) => {
  //随机从数组中获取一个值
  if (count === 1) {
    let index = Math.floor(Math.random() * arr.length);
    return [arr[index]];
  } else {
    let result = [];
    let newArr = [...arr]; // 组成的新数组初始化
    for (let i = 0; i < count; i++) {
      let index = Math.floor(Math.random() * newArr.length);
      let item = newArr[index];
      if (item) {
        result.push(item);
        newArr.splice(index, 1);
      }
    }
    return result
  }
}

// xmpp域名池检测
export const checkUrlPing = async (domains = []) => {
  for (let i = 0; i < domains.length; i++) {
    let domain = domains[i].split('://'); // wss://chat1.zedzedhhh1.cc:5443/ws
    let url = (domain[0] === 'wss' ? 'https://' : 'http://') + domain[1];
    try {
      let { status } = await axios.get(url);
      // 线路检测成功 才链接
      if (status === 200) {
        return domains[i];
        // eslint-disable-next-line no-unreachable
        break;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
  return '';
};

// 通过cust_id 请求获取ws账号密码
export const getXmppChatRoomPwd = async (payload = { userid: 0, islogin: false }) => {
  // 本地有缓存密码 用本地的
  const linkdata: any = JSON.parse(getSession(`CHATROOM_PWD_${payload.userid}`) || '{}');
  if (linkdata.username) {
    // 在后台配置的多域名中随机抽一个
    let domains = linkdata.domains?.split(',') ?? [];
    // 如果有配置域名连接池 才处理
    if (linkdata.domains || domains[0]) {
      let url = await checkUrlPing(domains);
      linkdata.ws = url || linkdata.ws;
    }
    return linkdata;
  }
  try {
    let sendData = { userid: payload.userid, islogin: payload.islogin, ...payload };
    let headers = { 'Content-Type': 'application/json' };
    const data: any = await reqApi('/extra/account', sendData, 'post', headers);
    data && setSession(`CHATROOM_PWD_${payload.userid}`, JSON.stringify(data || {})); // 保存本地密码
    // 在后台配置的多域名中随机抽一个
    let domains = data.domains?.split(',') ?? [];
    // 如果有配置域名连接池 才处理
    if (data.domains || domains[0]) {
      let url = await checkUrlPing(domains);
      data.ws = url || data.ws;
    }
    return data || null;
  } catch (err) {
    // mb_err(err.data || err.message, 2);
    return null;
  }
}
// 是否在线
export const isOnLINE = () => {
  const chatRoomStore = useChatRoomStore();
  const { isOnline } = storeToRefs(chatRoomStore);
  return isOnline;
}

// 获取房间的自动发送消息数据"action": "get_room_send","ct": "997-3","chat_name": "lottery_room"
export const getAutoMsg = async (payload = { ct: '', name: '' }) => {
  try {
    let sendData = { action: 'get_room_send', ...payload };
    let headers = { 'Content-Type': 'application/json' };
    const data = await reqApi('/extra/muc', sendData, 'post', headers);
    return data || null;
  } catch (err) {
    return null;
  }
}
// 通知后台 自动发送消息内容"action": "send_message","ct": "997-3","from": "fugui","to": "lottery_room",
// "content": "hello everybody"
export const sendAutoMsg = async (payload = { name: '' }) => {
  try {
    let sendData = { action: 'send_message', ...payload };
    let headers = { 'Content-Type': 'application/json' };
    const data = await reqApi('/extra/system', sendData, 'post', headers);
    return data || null;
  } catch (err) {
    return null;
  }
}
// 获取租户的房间列表 ct=18-1&page=1&size=20
export const reqChatRoomLists = async (payload = {}) => {
  try {
    const res = await reqApi('/extra/muc', payload, 'get')
    return Promise.resolve(res || {})
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getOpenImDomains = async () => {
  try {
    const res = await reqApi('/imApi/index/getDomains', {}, 'get')
    return Promise.resolve(res || {})
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取聊天室列表
export const reqChatOpenImRoomList = async (payload = {}) => {
  try {
    const res = await reqApi('/imApi/index/getRoomList', payload, 'get')
    return Promise.resolve(res || {})
  } catch (error) {
    return Promise.reject(error)
  }
}

// 加入openIm房间
export const joinOpenImChatRoom = async (payload = {}) => {
  try {
    const res = await reqApi('/imApi/user/joinGroup', payload, 'post')
    return Promise.resolve(res || {})
  } catch (error) {
    return Promise.reject(error)
  }
}

// openIm 发送消息
export const sendOpenImMessage = async (payload = {}) => {
  try {
    const res = await reqApi('/imApi/group/sendMessage', payload, 'post')
    return Promise.resolve(res || {})
  } catch (error) {
    return Promise.reject(error)
  }
}

// 获取openIm聊天记录
export const getOpenImMessage = async (payload = {}) => {
  try {
    const res = await reqApi('/imApi/group/getMessage', payload, 'get')
    const chatRoomStore = useChatRoomStore();
    chatRoomStore.setMessage(res.data)
    return Promise.resolve(res || {})
  } catch (error) {
    return Promise.reject(error)
  }
}

export const registerOpenIm = async (payload = {}) => {
  try {
    const res = await reqApi('/imApi/user/register', payload, 'post')
    return Promise.resolve(res || {})
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getAutoMessage = async (payload = {}) => {
  try {
    const res = await reqApi('/imApi/group/getAutoMessage', payload, 'get')
    return Promise.resolve(res || {})
  } catch (error) {
    return Promise.reject(error)
  }
}

// 虚拟会员列表 "action": "get_fake_for_room","ct": "997-3","name": "lottery_room","page": 1,"size": 20
export const xuniUserLists = async (payload = {}) => {
  try {
    const res = await reqApi('/extra/muc', { action: 'get_fake_for_room', ...payload }, 'post')
    return Promise.resolve(res || {})
  } catch (err) {
    return Promise.reject(err)
  }
}
// 获取指定房间的用户列表 action": "get_subscribers","ct": "997-3","name": "testroom","nickname": "","username": "","page": 1,"size": 10
export const getUsersByChatRoom = async (payload = {}) => {
  try {
    const res = await reqApi('/extra/muc', { action: 'get_subscribers', ...payload }, 'post')
    return Promise.resolve(res || {})
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取房间列表 传入vue对象实例
export const getRoomList = ($this: any) => {
  const chatRoomStore = useChatRoomStore();
  const { publicRooms } = storeToRefs(chatRoomStore);
  // 没有数据 则会ws请求
  !publicRooms.value.length && $this.$xmpp.getPublicMuc();
  return publicRooms;
}

// 获取 消息 当前聊天 当前聊天状态
export const getChatMainInfo = () => {
  const chatRoomStore = useChatRoomStore();
  const { messages, activeChat, isSendingInactiveChatStates, knownRooms, publicRooms, openImUserInfo } = storeToRefs(chatRoomStore);
  return { messages, activeChat, isSendingInactiveChatStates, knownRooms, publicRooms, openImUserInfo };
};
// 修改某条id的message
export const editChatMessageById = (messagelist: any = []) => {
  const chatRoomStore = useChatRoomStore();
  const { messages } = storeToRefs(chatRoomStore);
  const newMessages = [...messages.value];
  messagelist.forEach((message: any) => {
    let index = newMessages.findIndex(item => item.id === message.id);
    index > -1 && newMessages.splice(index, 1, message);
  });
  messages.value = newMessages;
  return { messages };
};

// 保存或者获取当前选中的彩种信息 有值为设置 无值为获取
export const setCurrentLotteryInfo = (lottery = null) => {
  if (lottery) {
    return setSession('CHATROOM_LOTTERY', JSON.stringify(lottery));
  }
  return JSON.parse(getSession('CHATROOM_LOTTERY') || '{}');
};

// 保存聊天室彩票数据 数组
export const chatRoomLotteryData = (data = null) => {
  if (data) {
    return setSession('CHATROOM_LOTTERY_LIST', JSON.stringify(data));
  }
  return JSON.parse(getSession('CHATROOM_LOTTERY_LIST') || '[]');
}
