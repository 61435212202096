
import Socket from '/@/plugins/public_utils';
import { useXianLuStore } from '/@/kernel-mn/stores/xianlu';

// 自动加载线路方法 返回websocket 实例
export const autoXianLu = async (urlObj = { requestType: 2 }) => {
  const xianlu = useXianLuStore();
  const chat = xianlu.chat;
  let socket = null;
  if (!chat.length) {
    await xianlu.fetchXianlu({ domain_type: '1,11' }); // 1聊天线路 11XMPP聊天线路
  }
  socket = new Socket();
  socket.initWebSocket(urlObj);
  return socket;
};

// 获取图片上传线路
export const getImgToken = (payload = {}) => {
  const xianlu = useXianLuStore();
  return xianlu.reqImgToken(payload);
};

// 获取线路后的上传动作 返回上传后的URL
export const getUpLoadUrl = (payload: any = {}) => {
  const xianlu = useXianLuStore();
  return xianlu.uploadImg(payload);
};
