// 第三方游戏相关
export default class game3Config {
  // 图标处理
  static gameArr = {
    video: [60, 345, 400, 402, 412, 420, 421, 423, 572, 924, 980, 1309, 1312, 1313, 1314, 1317, 1322],
    tiyu: [342, 490, 422, 409, 418, 570, 925, 930, 931, 981, 1031, 1308, 1316],
    dz: [340, 343, 403, 405, 413, 414, 415, 416, 507, 508, 571, 593, 707, 711, 926, 938, 939, 929, 960, 961, 1305, 1306, 983, 1311, 1315, 1318, 1319, 1320, 1321, 1323, 1324, 1325],
    qipai: [297, 299, 394, 401, 417, 509, 572, 709, 745, 927, 388, 382, 393, 404, 506, 391, 401, 928, 746, 1383],
    buyu: [708],
    glg: [340, 394, 401, 405, 415, 297, 299, 343, 507, 508, 509, 417, 571, 572, 927, 928],
    cp: [1385]
  }
}