/**
 * 聊天室
 */
import {defineStore} from 'pinia'
import appFetch from './appFetch'

export const useAppStore = defineStore({
  id: 'app',
  state: () => {
    return {
      data: {} as any,
      showSideMenu: false
    }
  },
  getters: {},
  actions: {
    ...appFetch,
    changeSideMenu(data: any) {
      this.showSideMenu = data;
    }
  }
})
